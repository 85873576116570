import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import Button from "../../Button";
import "../_modal.scss";
import "./_index.scss";

const ConfirmDeleteModal = ({ id, open, handleClose, data }) => {
  const action = data?.action
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === "backdropClick" && reason !== "escapeKeyDown") {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal confirm-delete-modal"
    >
      <div className="confirm-delete-modal-wrapper">
        <div className="modal-confirm">
          <div className="modal-content">
            <DialogTitle id="alert-dialog-title">
              <Button
                type="button"
                className="close"
                onClick={() => handleClose(id)}
                rightIcon={<CloseIcon />}
              />

              <div className="icon-box">
                <CloseIcon />
              </div>
              <span className="modal-title">Are you sure?</span>
            </DialogTitle>
            <DialogContent>
                <span className="modal-text">
                {`Do you really want to ${action} this ${data?.Item}?
                  ${action === "delete" ? "This process cannot be undone.": ""}
                `}
              </span>
              <div className="modal-actions">
                <Button
                  type="button"
                  text="Reset"
                  className="cancel-btn"
                  onClick={() => handleClose(id)}
                />
                <Button
                  type="button"
                  text={action?.charAt(0).toUpperCase() + action?.slice(1)}
                  className="delete-btn"
                  onClick={data?.deleteHandler}
                />
              </div>
            </DialogContent>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmDeleteModal;
